@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #586166;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-title {
  font-size: 30px;
  color: #203c77;
}

.section-home-title {
  font-size: 60px;
  color: #203c77;
  font-weight: 700;
  line-height: 76px;
}

.main-button-color {
  color: #203c77;
}

.text-input-disable {
  background-color: #9f9f9f;
}

.text-sub-title {
  font-size: 18px;
}

.main-footer {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  margin-top: auto;
  height: 60px;
}

.section-layout {
  margin-bottom: 200px;
}

.tooltip-text-color {
  color: #586166;
}

.MuiTooltip-arrow {
  color: #fff;
  background-color: #fff;
}